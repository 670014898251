export class Utils {
  public static getRandomColor(): string {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public static getRandomReference(): string {
    let letters = '0123456789ABCDEF';
    let value = '';
    for (let i = 0; i < 8; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    return value;
  }

  public static getRandomId(): string {
    let letters = '0123456789abcdef';
    // 37
    let value = '';
    for (let i = 0; i < 8; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    value += "-";
    for (let i = 0; i < 4; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    value += "-";

    for (let i = 0; i < 4; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    value += "-";
    for (let i = 0; i < 4; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    value += "-";
    for (let i = 0; i < 12; i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }

    return value;
  }


  /**
   * parses "product": "ARIESP2-ARCALPRIME-B50-230" to get {gasType: "ARCALPRIME", volume: 50}
   * @param {string} productString
   * @returns {any}
   */

  public static getGasTypeAndVolumeFromProductString(productString: string): any {
    let array = productString.split("-");
    if (!array) {
      console.error(`fail trying to parse product string ${array} : bad format`);
      return null;
    }
    if (array.length !== 4) {
      console.error(`fail trying to parse product string ${array} : bad format (should have 4 parts)`);
      return null;
    }
    let partOne: string = array[0];
    let gasTypeTrimed: string = array[1];
    let volumePart: string = array[2];
    let stuffEnd: string = array[3];
    if (partOne !== "ARIESP2") {
      console.error(`fail trying to parse product string ${array} : bad format (first part should be 'ARIESP2')`);
      return null;
    }

    if (!gasTypeTrimed || !gasTypeTrimed.length) {
      console.error(`fail trying to parse product string ${array} : bad format (gas type looks empty)`);
      return null;
    }

    if (!volumePart || volumePart.length <= 1 || volumePart[0] !== "B") {
      console.error(`fail trying to parse product string ${array} : bad format (volume part should start with 'B')`);
      return null;
    }
    let volume: number = Number(volumePart.substr(1));
    if (!volume) {
      console.error(`fail trying to parse product string ${array} : bad format (volume deduced is not a number)`);
      return null;
    }
    return {gasType: gasTypeTrimed, volume: volume};

  }


  static safeTrim(productName: string) {
    return productName ? productName.replace(/\s/g, "") : "";
  }

  /**
   * darken color for product cards in product page, for example
   * @param {string} color
   * @param {number} percent
   * @returns {string}
   */
  static shadeColor2(color: string, percent: number) {
    let f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = f >> 8 & 0x00FF, B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000
      + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);

  }
}
