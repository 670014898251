import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { User } from '../../shared/models/user';
import { ApiService } from '../../shared/services/alizent/api.service';
import { map } from 'rxjs/operators';
import { GLOBAL_INFORMATIONS_KEYS } from 'src/app/shared/utils/constants';
import { ProfileAuthorizations, IProfileAuthorization } from 'src/app/shared/enum/authorization-profile.enum';
import { MatSnackBar } from '@angular/material';
import { SiteService } from '@services/site.service';

@Injectable({
  providedIn: 'root',
})
export class TeamSettingsService {
  profileAuthorizations = ProfileAuthorizations;
  public userListToDisplay: Array<User>;
  public userListToDisplaySubject = new Subject<Array<User>>();

  private userList: Array<User>;
  private userListToAdd: Array<User> = [];
  private userListToDelete: Array<User> = [];

  constructor(private apiService: ApiService, private snackBar: MatSnackBar, private siteService: SiteService) {
    this.apiService.isLogout.subscribe({
      next: (isLogout) => {
        // if (isLogout) {
        //   this.purge();
        // }
      },
    });
    if (!this.siteService.currentSite) {
      this.siteService.getSite();
    }
  }

  /**
   * Return formatted team members list
   * @return {Observable<User[]>}
   */
  public getTeamMembers(): Observable<User[]> {
    return this.apiService.getTeamMembers().pipe(
      map((result) => {
        const users = result.content;
        this.userList = users.map((_user) => {
          const preferences = _user.preferences;
          const phoneNumber = preferences ? preferences.phoneNumber : null;

          const profile = _user.businessProfiles.find((profile) => profile.entityId == this.siteService.siteId);

          let authorization;

          if (profile && profile.name && this.profileAuthorizations[profile.name]) {
            authorization = (<IProfileAuthorization>this.profileAuthorizations[profile.name]).authorization;
          }
          return new User(_user.id, _user.firstname, _user.lastname, phoneNumber, _user.email, profile, false, null, authorization);
        });

        this.userListToDisplay = this.userList.filter((user) =>
          [ProfileAuthorizations.management.profile.toString(), ProfileAuthorizations.coordination.profile.toString()].includes(
            user.profile.name
          )
        );
        this.userListToDisplaySubject.next(this.userListToDisplay);
        return this.userList;
      })
    );
  }

  public getUserList(): Observable<User[]> {
    return this.getTeamMembers();
  }

  public getUserListToDisplay(): User[] {
    return this.userListToDisplay;
  }

  getUserById(userId: string): User {
    return this.userList.find((user) => user.id == userId);
  }

  addUser(user: User): void {
    let indexInRecieved = this.userList.findIndex((_user) => _user.id == user.id);
    let indexInToDelete = this.userListToDelete.findIndex((_user) => _user.id == user.id);
    let indexInToDisplay = this.userListToDisplay.findIndex((_user) => _user.id == user.id);
    if (indexInRecieved == -1) this.userListToAdd.push(user);

    if (indexInToDelete != -1) this.userListToDelete = this.userListToDelete.filter((_user) => _user.id !== user.id);
    if (indexInToDisplay == -1) this.userListToDisplay.push(user);

    this.userListToDisplaySubject.next(this.userListToDisplay);
  }

  deleteUser(user: User): void {
    let indexInToAdd = this.userListToAdd.findIndex((_user) => user.id == _user.id);
    if (indexInToAdd == -1) this.userListToDelete.push(user);
    else this.userListToAdd = this.userListToAdd.filter((_user) => user.id !== _user.id);

    this.userListToDisplay = this.userListToDisplay.filter((_user) => user.id !== _user.id);
    this.userListToDisplaySubject.next(this.userListToDisplay);
  }

  public updateTeamMember(user: User) {
    let phoneNumber = user.phone ? user.phone : null;
    let userToEdit = {
      firstname: user.firstname,
      lastname: user.lastname,
      preferences: { phoneNumber: phoneNumber },
      email: user.email,
      preferredUsername: user.firstname.toLowerCase() + '.' + user.lastname.toLowerCase(),
    };
    return new Observable((observer) => {
      this.apiService.updateTeamMember(userToEdit, user.id).subscribe((newUser) => {
        observer.next(newUser);
      });
    });
  }

  /**
   * Unassign Member Business Profile and delete it
   * @param user
   * @return {Observable}
   */
  public deleteTeamMember(user: User): Observable<any> {
    return new Observable((observer) => {
      if (user.profile) {
        this.apiService.unassignUserToBusinessProfiles(user.id, user.profile.id).subscribe(
          (result) => {
            this.apiService.deleteTeamMember(user.id).subscribe((data) => {
              observer.next();
            });
          },
          (error) => {
            this.snackBar.open(`ERROR DELETE for user ${user.email}: ${error.message}`, 'OK', { duration: 5000 });
            this.resetData();
          }
        );
      } else {
        this.apiService.deleteTeamMember(user.id).subscribe(
          (data) => {
            observer.next();
          },
          (error) => {
            this.snackBar.open(`ERROR DELETE for user ${user.email}: ${error.message}`, 'OK', { duration: 5000 });
            this.resetData();
          }
        );
      }
    });
  }

  public saveChanges(): void {
    if (this.userListToDelete.length) {
      this.userListToDelete.forEach((user) => {
        this.deleteTeamMember(user).subscribe(() => {
          this.snackBar.open('Reciepients deleted successfully', 'OK', { duration: 2000 });
          this.userListToDelete = [];
        });
      });
    }
  }

  public resetData() {
    this.userListToAdd = [];
    this.userListToDelete = [];
    this.userListToDisplay = this.userList.filter((user) =>
      [ProfileAuthorizations.management.profile.toString(), ProfileAuthorizations.coordination.profile.toString()].includes(
        user.profile.name
      )
    );
    this.userListToDisplaySubject.next(this.userListToDisplay);
  }

  private purge(): void {
    this.userList = null;
    this.resetData();
  }
}
